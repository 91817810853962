body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #181c3b;
  color: #d9d9d9;
}

a {
    color: pink;
    cursor: pointer;
}

.column {
    display: flex;
    flex-direction: column;
}

.holder {
    padding: 10px;
}

.itemHolder {
}

.player {
    color: white;

    background: deeppink;
    padding: 10px;

    border-radius: 1337px;
}

.playerHolder {
    position: absolute;
    bottom: 10px;
    right: 30px;

    align-items: flex-end;
}

.row {
    display: flex;
    flex-direction: row;
}

.soundItem {
    justify-content: space-between;

    cursor: pointer;

    background-color: black;
    margin: 10px 0;
    padding: 5px 10px;
}

.soundItem:hover {
    background-color: #333;
}
